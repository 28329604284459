@import '../../../scss/utils/variables';

.input {
  width: 100%;
  padding: 10px;
  margin-top: 6px;
  background-color: rgba($color: #000000, $alpha: 0);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  outline: none;

  &.light {
    border: 2px solid #d4d4d4;
    color: $black-foreground-color;
    &::-webkit-calendar-picker-indicator {
      filter: invert(0.5);
    }
    &:focus {
      border: 2px solid $primary-color;
    }
  }

  &.dark {
    border: 2px solid #4f4f51;
    color: $white-foreground-color;
    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
    &:focus {
      border: 2px solid $primary-color;
    }
  }
}
