.tag {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 16px;
  width: fit-content;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
