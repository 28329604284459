@import '../../scss/utils/variables';

.nav {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.button,
.arrowButton {
  padding: 8px 10px;

  &:disabled {
    background-color: $primary-color;
    border: 2px solid $primary-color;
  }
}

.arrowButton {
  border: 2px solid $primary-color;
  &:disabled {
    color: $gray-color;
    background-color: rgba($color: $gray-color, $alpha: 0.2);
    border: 2px solid $gray-color;
  }
}
