@import '../../scss/utils/variables';
@import '../../scss/utils/mixins';

.filterContainer {
  position: relative;

  .searchBar {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0px 15px;
    gap: 8px;
    border-bottom: 2px solid rgba(72, 73, 75, 0.5);

    &.light {
      background-color: #fff;

      input {
        color: $black-text-color;
      }
    }

    &.dark {
      background-color: #1A1F2D;

      input {
        color: $white-text-color;
      }
    }

    .searchInput {
      flex-grow: 1;
      border: none;
      outline: none;
      height: 30px;
      background: none;
      font-size: 18px;
      caret-color: $primary-color;
      cursor: pointer;

      &::after {
        content: 'Filter';
        position: absolute;
        margin-top: 3px;
        color: $gray-color;
      }
    }

    .filterButton {
      cursor: pointer;
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: fit-content;
    z-index: 10;

    padding: 8px 0px;
    border-radius: 4px;
    border: 1px solid rgba($color: gray, $alpha: 0.5);

    &.light {
      background: $white-background-color;

      button {
        color: $black-text-color;
      }
    }

    &.dark {
      background: $black-background-color;

      button {
        color: $white-text-color;
      }
    }

    button {
      display: block;
      width: 100%;
      text-align: left;
      padding: 8px 16px;
      border: none;
      font-size: 16px;
      background: none;
      // color: $white-text-color;
      cursor: pointer;
      &:hover {
        background-color: rgba($color: white, $alpha: 0.2);
      }
    }
  }

  .searchModal {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    top: 100%;
    left: 0;
    width: fit-content;
    z-index: 10;

    padding: 8px;
    border-radius: 4px;
    border: 1px solid rgba($color: gray, $alpha: 0.5);

    &.light {
      background: $white-background-color;
    }

    &.dark {
      background: $black-background-color;
    }

    h3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: capitalize;
      padding-bottom: 4px;
      border-bottom: 2px solid rgba(153, 153, 153, 0.3);

      svg {
        cursor: pointer;
      }
    }

    input {
      padding: 4px 6px;
    }

    .applyButton {
      margin: 8px 0px;
      font-size: 16px;
      padding: 6px;
    }
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.customCheckbox {
  width: 24px;
  height: 24px;
  border: 2px solid #cccccc;
  border-radius: 5px;
  appearance: none;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.customCheckbox:checked {
  background-color: #f64644;
  border-color: #f64644;
}

.customCheckbox:checked::after {
  content: '';
  width: 12px;
  height: 6px;
  border: solid white;
  border-width: 0 0 3px 3px;
  transform: rotate(-45deg);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.checkboxLabel {
  font-size: 14px;
  cursor: pointer;
}
