@import '../../../scss/utils/variables';

.dropdown {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-height: 58px;
  padding: 20px 30px;
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);

  hr {
    margin: 20px 0px;
    border: 1px solid rgba($color: #48494b, $alpha: 0.9);
  }

  &.light {
    background-color: $white-background-color;
  }

  &.dark {
    background-color: $black-text-color;
  }
}

.reactivateButtonVisible {
  visibility: hidden;
  height: 25px;
  width: 180px;
  font-size: 15px;
  line-height: 10px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tableStyle {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    color: white;
  }

  &.light {
    background-color: #f4f7f6;

    th {
      background-color: #a62927;
    }

    .tableHeader {
      background-color: #f0f0f0;
    }

    .tableRow:nth-child(even) {
      background-color: #f2f2f2;
    }
  }

  &.dark {
    background-color: #333537;

    th {
      background-color: #e73d44;
      border-color: #333537;
    }

    .tableHeader {
      background-color: #e73d44;
    }

    td {
      border-color: #5f6163;
    }
  }
}

.modalButtons {
  display: flex;
  gap: 20px;

  button {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.hftButton {
  height: 25px;
  width: 180px;
  font-size: 15px;
  line-height: 10px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icons {
  display: flex;
  gap: 12px;
  svg {
    cursor: pointer;
  }
}

.header,
.content {
  display: flex;
  justify-content: space-between;
}

.content {
  width: 80%;
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.active {
  max-height: 9999px;
}
.choice {
  display: flex;
  gap: 10px;
}
