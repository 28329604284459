@import '../../../scss/utils/variables';

.wrapper {
  overflow-x: hidden;
}

.title {
  font-size: 18px;
  margin-bottom: 15px;
}
.accountContainer {
  display: flex;
}

.example {
  color: gray;
  font-size: 0.9em;
}

.returnButton {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.downloadButton {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.dropdown {
  width: 100%;
  cursor: pointer;
  font-size: 20px;

  &[open] {
    & summary::before {
      transform: rotate(180deg);
    }
  }

  summary {
    padding: 15px;
    border-radius: 5px;
    background-color: $primary-color;
    list-style-type: none;
    user-select: none;

    &::before {
      content: url('../../../assets/icons/arrow.svg');
      display: inline-block;
      margin-right: 10px;
      transition: transform 0.4s ease-out;
    }
  }

  .container {
    border-radius: 5px;
    background-color: lighten($color: $black-background-color, $amount: 5);
    padding: 25px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .formWrapper {
      width: 50%;
    }
  }
}

.personalInformations,
.accountInformations,
.financialInformations,
.files {
  width: 50%;
  padding: 30px;
}

.accountInformations {
  border-left: 1px solid $gray-color;
  border-bottom: 1px solid $gray-color;
}

.personalInformations {
  border-right: 1px solid $gray-color;
  border-bottom: 1px solid $gray-color;
}

.files {
  border-left: 1px solid $gray-color;
  border-top: 1px solid $gray-color;

  .title {
    margin-top: 20px;
  }
}

.financialInformations {
  border-right: 1px solid $gray-color;
  border-top: 1px solid $gray-color;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.buttons {
  display: flex;
  margin-top: 15px;
  gap: 10px;
}

.card {
  width: 100%;
  border-radius: none;
}

.challengesContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 15px;
  padding: 20px;

  &.dark {
    background-color: $black-background-color;
  }

  &.light {
    background-color: #ddd;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  button {
    width: fit-content;
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 0px 2vw;

  .forms {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }
}

.formContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.redButton,
.greenButton,
.primaryButton {
  text-align: center;
  width: 200px;
  padding: 6px 32px;
  border-radius: 7px;
  background-color: rgba($color: #000000, $alpha: 0);
}

.redButton {
  border: 2px solid orange;
  color: orange;
}

.greenButton {
  border: 2px solid $green-color;
  color: $green-color;
}

.primaryButton {
  background-color: $primary-color;
  border: 2px solid $primary-color;
  color: $white-text-color;
}

.affiliateDropdownContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.table {
  width: 100%;
  margin-top: 30px;
  border-radius: 6px;
  font-weight: 600;
  border-collapse: collapse;
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);

  &__header tr th {
    text-align: left;
    padding: 15px 25px;
    border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.5);
  }

  &__body tr {
    border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.5);
    &:last-child {
      border-bottom: none;
    }
    td {
      padding: 15px 25px;
    }
    .state {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &.light {
    background-color: $white-foreground-color;

    .table__body tr td:nth-child(2n) {
      background-color: #f3f3f3;
    }
  }

  &.dark {
    background-color: #1A1F2D;

    .table__body tr td:nth-child(2n) {
      background-color: #131722;
    }
  }
}
