.banner {
  background-color: rgba(23, 90, 223, 0.3) !important;
  border: 2px solid #175adf;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 80px;
}

.cards {
  border-radius: 8px;
  padding: 16px;
  width: fit-content;

  img {
    border-radius: 6px;
  }
}

.title {
  width: fit-content;
  padding: 8px 24px;
  border-radius: 6px;
}
