@import '../../../scss/utils/variables';

.title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 20px;

  .chip {
    font-size: 18px;
    background-color: $primary-color;
    color: white;
    border-radius: 2px;
    padding: 4px 6px;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.exportXLS {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin-top: 25px;
  color: #b7b7b7;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}
