@import '../../../scss/utils/variables';

.tableContainer {
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 30px;
  &::-webkit-scrollbar {
    display: block;
    height: 8px;
  }

  &:hover::-webkit-scrollbar {
    display: block;
  }

  .modalButtons {
    display: flex;
    gap: 20px;

    button {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.actionColumn tr td {
  border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.5);
}

.challengesTable {
  min-width: 100%;
  font-size: 0.9em;
  font-weight: 400;
  border-collapse: collapse;
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);

  .challengesTable__body tr td {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 1.2;
  }

  .tableHead tr th {
    white-space: nowrap;
    text-align: center;
    padding: 15px 20px;
    border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.3);
  }

  .challengesTable__body svg {
    cursor: pointer;
  }

  .tableHead tr th.actionColumn,
  .challengesTable__body tr td.actionColumn {
    position: sticky;

    right: 0;
    z-index: 100;
    background-color: #1A1F2D;
  }

  &__body tr {
    text-align: center;
    &:last-child {
      border-bottom: none;
    }
    td {
      white-space: nowrap;
      padding: 20px;
      border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.2);
    }
  }

  .challengesTable__body tr {
    border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.5);
  }

  &.light {
    background-color: $white-foreground-color;

    .challengesTable__body tr td:nth-child(2n) {
      background-color: #f3f3f3;
    }

    .tableHead tr th.actionColumn {
      background-color: $white-foreground-color;
    }
    .challengesTable__body tr td.actionColumn {
      background-color: #f3f3f3;
    }
  }

  &.dark {
    background-color: #1A1F2D;

    .challengesTable__body tr td:nth-child(2n) {
      background-color: #131722;
    }

    .tableHead tr th.actionColumn,
    .challengesTable__body tr td.actionColumn {
      background-color: #1A1F2D;
    }
  }
}

.actions {
  display: flex;
  justify-content: center;
  gap: 12px;
}
