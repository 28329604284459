@import '../../scss/utils/variables';

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 8px;
}

.header {
  th {
    padding: 8px;
    font-weight: 600;
    border-right: 2px solid #cccccc;

    &:first-child {
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
      border: none;
    }
  }

  &.light th {
    background-color: #f3f3f3;
  }

  &.dark th {
    background-color: $black-background-color;
  }
}

.body {
  td {
    text-align: center;
    padding: 8px;
  }

  &.light {
    &:nth-child(2n) td {
      background-color: #f3f3f3;
    }
  }

  &.dark {
    &:nth-child(2n) td {
      background-color: $black-background-color;
    }
  }
}
