.stepperContainer {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    height: 100vh;
  }
  
.stepContent {
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 80%;

  .selected_challenge_container {

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .selected_challenge_title_container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 31px;

      .selected_challenge_title {
        display: flex;
        align-items: center;

        padding: 0 10px 0 10px;
        border-radius: 10px;
        height: 100%;
        font-weight: bold;

        &.dark {
          background-color: #5b3b3d;
        }
        &.light {
          background-color: #e5c0c2;
        }
      }
    }

    .selected_challenge_content_container {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .buttons_choice_container {
      display: flex;
      justify-content: center;
      gap: 15px;
    }
    
  }
}
  