.chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  width: 69%;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}
