@import '../../../scss/utils/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 80vh;
  text-align: center;

  @media screen and (max-width: 1023px) {
    height: 75vh;
  }

  h1 {
    font-size: clamp(24px, 2.5vw, 5vw);
    color: $primary-color;
  }

  p {
    font-size: clamp(16px, 2vw, 3vw);
    font-weight: 700;
  }

  button {
    margin-top: 20px;
    font-size: clamp(12px, 1vw, 2vw);
  }
}
