@import '../../../scss/utils/variables';
@import '../../../scss/utils/mixins';

.card {
  padding: 20px;
  max-height: 156px;
  overflow: hidden;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  @include themes;

  h2 {
    font-weight: 600;
    font-size: 18px;
  }

  @media screen and (min-width: 545px) and (max-width: 768px) {
    max-height: 160px;
  }
  @media screen and (min-width: 501px) and (max-width: 544px) {
    max-height: 180px;
  }
  @media screen and (min-width: 280px) and (max-width: 500px) {
    max-height: 250px;
  }
  @media screen and (min-width: 769px) and (max-width: 893px) {
    max-height: 190px;
  }
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  // gap: 20px;

  button {
    padding: 5px 16px;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .payout button {
    background-color: $primary-color;
    &:hover {
      background-color: lighten($color: $primary-color, $amount: 8);
    }
  }
  .upgrade button {
    background-color: $green-color;
    &:hover {
      background-color: lighten($color: $green-color, $amount: 20);
    }
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
}

.main {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0px 30px 0px;

  .grayText {
    color: #b7bfcc;
  }

  .yellowText {
    color: $yellow-color;
  }

  .invisible {
    font-size: 24px;
    // margin-bottom: 30px;
  }

  // @media screen and (max-width: 895px) {
  //   .invisible {
  //     margin-bottom: 80px;
  //   }
  // }

  @media screen and (max-width: 1023px) {
    font-size: 16px;
    gap: 15px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 5px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;

  p {
    text-decoration: underline;
  }

  p,
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
}

.moreContent {
  margin-top: 30px;
  padding: 0px clamp(20px, 2vw, 50px);
  overflow-x: scroll;

  hr {
    margin-bottom: 30px;
    border: 1px solid rgba($color: #999, $alpha: 0.3);
  }
}

.fundedCard {
  @media screen and (max-width: 374px) {
    max-height: 250px;
  }
}

.active {
  max-height: 9999px;
}
