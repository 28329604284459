@import '../../../scss/utils/variables';
@import '../../../scss/utils/mixins';

.logo {
  height: 50px;
  width: auto;
}

.header {
  position: sticky;
  width: 100%;
  top: 0px;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  padding: 20px 50px;
  @include themes;

  .openButton {
    opacity: 1;
    &.active {
      opacity: 0;
    }
  }
}

.openButton,
.closeButton {
  cursor: pointer;
}

.container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
  display: none;
  justify-content: space-between;
  flex-direction: row-reverse;

  .closeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-right: 25px;
    padding: 5px;
    height: fit-content;
    border-radius: 100%;
    background-color: rgba($color: $primary-color, $alpha: 0.5);
  }

  &.active {
    display: flex;
  }
}
