@import '../../scss/utils/variables';
.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media screen and (max-width: 1439px) {
    .card {
      flex-grow: 1;
    }

    .lastCard {
      width: 100%;
    }
  }
}

.withdrawButtons {
  display: flex;
  gap: 12px;
}

.accountCardsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
}

.sectionTitle {
  display: flex;
  align-items: center;
  margin: 50px 0px 20px 0px;
  gap: 15px;
}

.accountContent {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: space-between;

  .chart {
    width: 60%;
    height: 100%;
    @media screen and (max-width: 1600px) {
      width: 60%;
    }
    @media screen and (max-width: 1518px) {
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      min-width: 800px;
    }
  }
}

.noAccountMessage {
  text-align: center;
  width: 24px;
  font-weight: bold;
  width: 100%;
}

.tradesInfos {
  h2 {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .cards {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    flex-wrap: wrap;
  }

  .card {
    display: flex;
    flex-direction: column;
    flex: 20%;
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding: 20px 50px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    p {
      font-weight: 700;
      margin-top: 20px;
    }

    &.dark {
      background-color: #1A1F2D;
    }

    &.light {
      background-color: $white-background-color;
    }
  }
}

.challengesTable {
  margin-bottom: 30px;
  font-weight: 600;
  border-collapse: collapse;
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 1518px) {
    width: 100%;
  }

  &__header tr th {
    text-align: left;
    padding: 15px 25px;
    border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.5);
  }

  &__body tr {
    border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.5);
    &:last-child {
      border-bottom: none;
    }
    td {
      padding: 15px 25px;
    }

    .status {
      color: $green-color;
    }
  }

  &.light {
    background-color: $white-foreground-color;

    .challengesTable__body tr td:nth-child(2) {
      background-color: #f3f3f3;
    }
  }

  &.dark {
    background-color: #1A1F2D;

    .challengesTable__body tr td:nth-child(2) {
      background-color: #131722;
    }
  }
}

.credentials {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    gap: 15px;
  }

  input {
    background: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: $white-text-color;
    width: 100%;
  }
}

.credentialsPassword {
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    cursor: pointer;
  }

  &.light {
    input {
      color: $black-text-color;
    }
  }
}

.credentialsLine {
  outline: none;
  border: 1px solid #5c5c5e;
}

.copyButton {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.scaleUpModal {
  ul {
    list-style-type: disc;
    margin-bottom: 16px;
  }

  .buttons button:first-child {
    background-color: $green-color;
  }
}

.payoutModal {
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
}

.payoutModal p {
  margin-bottom: 10px; /* Ajustez selon les besoins pour l'espacement vertical */
  font-weight: 600; /* Pour semi-bold, ajustez selon les besoins */
}

.max-amount-text {
  color: darkgrey;
  font-size: 15px;
}

.scaleUpModal,
.payoutModal {
  font-size: 18px;

  .buttons {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 28px;

    button {
      font-size: 16px;
      font-weight: 600;
      flex: 2;
    }
  }
}

.stepper {
  button,
  label {
    margin-top: 20px;
    width: 100%;
  }
}
