@import '../../scss/utils/variables';
@import '../../scss/utils/mixins';

.container {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: $black-background-color, $alpha: 0.9);

  &.fullOpacity {
    background-color: rgba($color: $black-background-color, $alpha: 1);
  }

  &.active {
    display: flex;
  }
}

.card {
  padding: 0px;
  width: 60%;
  @include themes;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 2px solid rgba($color: #999999, $alpha: 0.3);

  h1 {
    font-size: 24px;
    font-weight: 600;
  }

  .closeButton {
    cursor: pointer;
  }
}

.main {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  max-height: 80vh;
  overflow-y: scroll;
}

@media screen and (max-width: 926px) {
  .card {
    width: 95%;
  }
}
