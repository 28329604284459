@import '../../scss/utils/variables';
@import '../../scss/utils/mixins';

.mainContainer {
  display: flex;
  gap: 20px;
  max-width: 100%;

  @media screen and (max-width: 1439px) {
    flex-wrap: wrap;
  }
}

.errorMsg {
  color: #ff6b6b;
}

.conditions {
  margin-top: 50px;
  width: 100%;
  overflow-x: scroll;

  .conditionTable {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 18px;
    margin-top: 30px;

    -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);

    tr th,
    tr td {
      padding: 16px;
    }

    &.dark {
      .conditionTable__header {
        // background-color: $black-background-color;
        background-color: #1b1b22;
      }

      .conditionTable__body tr td:nth-child(2n) {
        // background-color: $black-background-color;
        background-color: #1b1b22;
      }
    }

    &.light {
      .conditionTable__header {
        background-color: $white-background-color;
      }
      .conditionTable__body tr td:nth-child(2n) {
        background-color: $white-background-color;
      }
    }

    &__header {
      tr th {
        border-bottom: 2px solid rgba($color: #cccccc, $alpha: 0.5);
        border-right: 2px solid #cccccc;

        &:last-child,
        &:first-child {
          border-right: none;
        }
      }
    }

    &__body {
      tr td {
        border-bottom: 2px solid rgba($color: #cccccc, $alpha: 0.5);

        &:first-child {
          text-align: left;
        }
      }

      tr:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.challengeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .card,
  .infoCard {
    flex: 40%;

    @media screen and (max-width: 1439px) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}

.card {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: auto;
  flex: 1 1 auto;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;

  @media screen and (max-width: 1439px) {
    flex-basis: 50%;
    max-width: 50%;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      height: 3px;
      background-color: $primary-color;
      width: 60%;
      top: 35px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.walletCheckbox {
  margin-top: 60px;
}

.table {
  font-size: 14px;
  white-space: nowrap;
  border-radius: 6px;
  font-weight: 600;
  border-collapse: collapse;

  @media screen and (max-width: 425px) {
    font-size: 12px;
  }

  &.dark .table__header tr th,
  &.light .table__header tr th {
    &:nth-child(1) {
      background-color: transparent;
    }
  }

  &.dark {
    .table__header tr th {
      // background-color: #2a2b2e;
      background-color: #1b1b22;
    }
    .table__body tr td {
      // background-color: #131722;
      background-color: #22222c;
    }
    tr:nth-child(1),
    tr:nth-child(2),
    tr:nth-child(3) {
      td:nth-child(1) {
        // background-color: #2a2b2e;
        background-color: #1b1b22;
      }
    }
  }

  &.light {
    .table__header tr th {
      background-color: #f4f4f4;
    }
    .table__body tr td {
      background-color: #ffffff;
    }
    tr:nth-child(1),
    tr:nth-child(2),
    tr:nth-child(3) {
      td:nth-child(1) {
        background-color: #f4f4f4;
      }
    }
  }

  &__header tr {
    th {
      text-align: left;
      padding: 15px 25px;
      @media screen and (max-width: 425px) {
        padding: 5px 15px;
      }

      &:nth-child(2) {
        border-top-left-radius: 10px;
      }

      &:nth-child(3) {
        border-top-right-radius: 10px;
      }
    }
  }

  &__body tr td {
    padding: 15px 25px;
    @media screen and (max-width: 425px) {
      padding: 5px 15px;
    }
  }

  &__body tr:nth-child(1) {
    td:nth-child(1) {
      border-top-left-radius: 10px;
    }
  }

  &__body tr:nth-child(3) {
    td:nth-child(1) {
      border-bottom-left-radius: 10px;
    }
    td:nth-child(3) {
      border-bottom-right-radius: 10px;
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  .prices {
    display: flex;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 425px) {
      font-size: 14px;
    }

    .price {
      color: #7cb0ff;
      padding: 5px 12px;
      border-radius: 20px;
      font-weight: bold;
      -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);

      &.dark {
        background-color: #2e2f33;
      }

      &.light {
        background-color: #f4f4f4;
      }
    }

    .strikePrice {
      color: #999999;
      font-weight: bold;
      text-decoration: line-through;
    }
  }
}

.infoCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px 30px 40px 30px;

  .iconContainer {
    margin-bottom: 18px;

    .icon {
      position: absolute;
      top: 20px;
      left: 0px;
      right: 0px;
    }
  }

  .content {
    flex-grow: 1;
    h2,
    h3 {
      font-size: 20px;
      font-weight: 600;

      span {
        color: #00c7b4;
      }
    }
    p {
      font-size: 18px;
      margin: 20px 0px;

      span {
        color: #00c7b4;
      }
    }
  }

  button {
    width: 100%;
    font-weight: 700;
    font-size: 16px;
  }
}

.sidebar {
  flex: 1;
  min-width: 450px;
  position: sticky;
  position: -webkit-sticky;
  min-height: 800px;
  height: 100%;
  max-height: 100%;
  top: -40px;

  @media screen and (max-width: 1439px) {
    flex-basis: 100%;
    min-width: 100%;
    max-height: 100%;
  }

  h2 {
    margin-bottom: 30px;
  }

  .line {
    height: 2px;
    background-color: rgba($color: #ffffff, $alpha: 0.3);
    border: #ffffff;
    outline: none;
    box-shadow: none;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .paymentButton {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    margin-top: 20px;
    @media screen and (max-width: 767px) {
      padding-left: 6px 12px;
      font-size: 14px;
    }
  }
  .bonus {
    color: #00c7b4;
  }

  .formGroup {
    display: flex;
    gap: 20px;
    margin-bottom: 25px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .form {
      width: 50%;
      flex-grow: 1;
      font-weight: 600;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .applyButton {
      align-self: flex-end;
      padding: 12px 24px;
      font-size: 16px;
      font-weight: 600;
      @media screen and (max-width: 767px) {
        padding-left: 6px 12px;
        font-size: 14px;
        align-self: center;
      }

      &.dark {
        background-color: $white-background-color;
        color: $black-text-color;
      }
      &.light {
        background-color: $black-background-color;
        color: $white-text-color;
      }
    }
  }

  .aboutContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
    max-height: 110px;

    .about {
      display: flex;
      justify-content: space-between;
      font-size: 18px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
  }
}

.nav {
  margin-bottom: 50px;
  margin-top: -70px;

  .mobileNav {
    display: none;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .mobileButtons {
      display: flex;
      gap: 20px;
    }

    img {
      width: 50px;
      height: 50px;
    }

    @media screen and (max-width: 1024px) {
      display: flex;
    }
  }

  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
      display: flex;
      gap: 40px;
      font-size: 18px;
      overflow-y: scroll;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    li a {
      color: white;
      text-decoration: none;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }

    img {
      height: 80px;
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    div {
      display: flex;
      gap: 20px;
      button {
        font-size: 16px;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
    }
  }
}

.textField {
  width: 100%;
  padding: 10px;
  margin-top: 6px;
  background-color: rgba($color: #000000, $alpha: 0);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  outline: none;

  &.light {
    border: 2px solid #d4d4d4;
    input {
      color: $black-foreground-color;
      &::-webkit-calendar-picker-indicator {
        filter: invert(0.5);
      }
    }
  }

  &.dark {
    border: 2px solid #4f4f51;
    input {
      color: $white-foreground-color;
      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }
  }
}

.mainFooter {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 30px;

  .copyright {
    text-align: center;
  }
}

.errorMessage {
  margin-bottom: 6px;
  color: $primary-color;
}
