* {
  scrollbar-width: auto;
  scrollbar-color: #175adf;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }
  ::-webkit-scrollbar-track {
    background: rgba($color: #000000, $alpha: 0);
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #175adf;
    border-radius: 10px;
  }
}
