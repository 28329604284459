@mixin themes {
  &.light {
    background-color: $white-foreground-color;
    color: $black-text-color;
  }

  &.dark {
    background-color: $black-foreground-color;
    color: $white-text-color;
  }
}
