.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  .modalContent {
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    border-radius: 10px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding:10px;
    width: auto;
    height: auto;
  }
  
  .loaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2100;
  }
  
  .errorMessage {
    color: #d32f2f;
    font-size: 16px;
    text-align: center;
    margin: 20px;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0.7); /* Fond sombre /
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: red($color: #000000); / Couleur de l'icône en blanc */
    padding: 4px;
    z-index: 2100;
    transition: transform 0.2s ease, background-color 0.2s ease;
  }
  
  .closeButton:hover {
    transform: scale(1.1);
    background-color: #000;
  }