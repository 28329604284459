@import '../../../scss/utils/variables';

.title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 20px;

  .chip {
    font-size: 18px;
    background-color: $primary-color;
    color: white;
    border-radius: 2px;
    padding: 4px 6px;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footerActions {
  display: flex;
  gap: 16px;
}

.action {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin-top: 25px;
  color: #b7b7b7;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.formContainer {
  display: flex;
  gap: 24px;
}

.formGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 16px 0px;
}
