.summary {
  padding: 16px;
  // width: 30%;
}

.summaryCard {
  border: 2px solid #697399;
  padding: 8px;
  flex: 1 1 calc(50% - 16px);
}
