@import '../../scss/utils/variables';

.tabContainer {
  position: relative;
}

.header {
  margin-bottom: 50px;
  justify-content: space-around;
  display: flex;
  border-bottom: 2px solid $gray-color;
  overflow-x: scroll;

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .tab {
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    padding: 8px 12px;
    font-weight: 600;

    white-space: nowrap;

    &.active {
      color: $primary-color;
    }
  }

  .slider {
    position: absolute;
    bottom: 0px;
    height: 2px;
    background-color: $primary-color;
    transition:
      width 0.3s ease-in-out,
      left 0.3s ease-in-out;
  }
}
