@import '../../scss/utils/variables';
@import '../../scss/utils/mixins';

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 50px;

  .card {
    flex-grow: 1;
  }

  .modalCard {
    flex-grow: 1;
    h3 {
      font-size: 16px;
    }
    p {
      font-size: 18px;
    }
    svg {
      height: 30px;
      width: auto;
    }
  }

  @media screen and (max-width: 1439px) {
    .card {
      flex-grow: 1;
    }

    .lastCard {
      width: 100%;
    }
  }
}

.payoutDropdown {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.header {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.exportXLS {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin-top: 25px;
  color: #b7b7b7;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.noActivity {
  text-align: center;
}

.filter {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 20px;

  button {
    font-size: 14px;
    font-weight: bold;
  }
}

.affiliateCard {
  width: 100%;
  margin: 32px 0px;

  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;

  .tag {
    position: absolute;
    right: 20px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 20px;

    h3 {
      font-weight: 400;
    }

    .affiliateId,
    .affiliateLink {
      margin-top: 12px;
      padding: 6px;
      border-radius: 5px;
    }

    .affiliateId {
      background-color: #e9e9e9;
      color: $black-text-color;
    }

    .affiliateLink {
      width: 400px;

      @media screen and (max-width: 1023px) {
        width: auto;
      }
    }

    .linkContent {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 20px;

      button {
        padding: 8px 16px;
        font-size: 16px;
        font-weight: 600;
        align-self: flex-end;
      }
    }
  }
}

.tablesContainer {
  display: flex;
  flex-wrap: wrap;

  gap: 50px;

  .commissions,
  .referrals {
    flex-grow: 1;
    overflow-x: scroll;
    h2 {
      margin-bottom: 20px;
    }
  }
}

.redButton,
.greenButton,
.primaryButton {
  text-align: center;
  width: 200px;
  padding: 6px 32px;
  border-radius: 7px;
  background-color: rgba($color: #000000, $alpha: 0);
}

.redButton {
  border: 2px solid orange;
  color: orange;
}

.greenButton {
  border: 2px solid $green-color;
  color: $green-color;
}

.primaryButton {
  background-color: $primary-color;
  border: 2px solid $primary-color;
  color: $white-text-color;
}
