@import '../../../scss/utils/variables';

.black {
  fill: $black-text-color;

  &.dark {
    fill: $white-text-color;
  }
}

.white {
  fill: $white-text-color;

  &.light {
    fill: $black-text-color;
  }
}

.constant-black {
  fill: $black-text-color;
}

.constant-white {
  fill: $white-text-color;
}

.gray {
  fill: $gray-color;
}
