@import '../../scss/utils/variables';
@import '../../scss/utils/mixins';

.container {
  border-radius: 10px;
  padding: 10px 0px !important;
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba($color: gray, $alpha: 0.5);
  @include themes;

  .lang {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color: gray, $alpha: 0.3);
    }

    .flag {
      width: 24px;
      height: 18px;
      border-radius: 2px;
    }
  }
}
