.card {
  flex: 1;
  min-width: 250px;
  padding: 16px;
}

.header {
  display: flex;
  align-items: flex-start;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.125rem;
  height: 3.125rem;
  padding: 8px;
  border-radius: 6px;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
