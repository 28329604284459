$primary-color: #f64644;

$green-color: #19b580;
$yellow-color: #ffc737;
$light-blue-color: #3988ff;
$gray-color: #909090;

$white-text-color: #ffffff;
$white-background-color: #f4f4f4;
$white-foreground-color: #ffffff;

$black-text-color: #333333;
$black-background-color: #1e1e1e;
$black-foreground-color: #131722;
