@import '../../../scss/utils/variables';

.container {
  display: flex;
  align-items: baseline;
}

.button {
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  background-color: transparent;
  border: 2px solid #999999;
  padding: 8px 15px;
  margin-top: 6px;
  color: #999999;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;

  svg {
    fill: #999999 !important;
  }
}

.filenames {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 10px;
}

.filename {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 3px;
  color: #999999;
  cursor: pointer;

  &:hover {
    color: $primary-color;

    svg {
      fill: $primary-color !important;
    }
  }

  svg {
    fill: #999999 !important;
  }
}

.input {
  display: none;
}
