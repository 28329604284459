@import '../../../scss/utils/variables';

.tag {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 13px;
  margin: 8px 0px;

  &.light {
    background-color: #a8a4a4;
  }

  &.dark {
    background-color: #606060;
  }
}

.button {
  cursor: pointer;
  margin-top: 4px;
}
