.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 40%;
  // width: 35%;
  max-width: 90%;
}

.content {
  padding-top: 24px;
}
