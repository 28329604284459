@import '../../scss/utils/variables';

/* Dans votre fichier CSS */
.h2-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.max-balance-text {
  color: darkgrey;
  font-size: 15px;
}
.tooltip-text {
  font-size: 1rem; /* Ajustez cette valeur selon vos besoins */
}

.withdrawButtons {
  display: flex;
  gap: 12px;
}

.accountContent {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: space-between;

  .chart {
    width: 60%;
    height: 100%;
    @media screen and (max-width: 1600px) {
      width: 50%;
    }
    @media screen and (max-width: 1518px) {
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      min-width: 800px;
    }
  }
}

.challengesTable {
  margin-bottom: 30px;
  font-weight: 600;
  border-collapse: collapse;
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 1518px) {
    width: 100%;
  }

  &__body tr {
    border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.5);
    &:last-child {
      border-bottom: none;
    }
    td {
      padding: 15px 25px;
    }

    .status {
      color: $yellow-color;
    }
  }

  &.light {
    background-color: $white-foreground-color;

    .challengesTable__body tr td:nth-child(2) {
      background-color: #f3f3f3;
    }
  }

  &.dark {
    background-color: #1A1F2D;

    .challengesTable__body tr td:nth-child(2) {
      background-color: #131722;
    }
  }
}

.table {
  width: 100%;
  margin-top: 30px;
  border-radius: 6px;
  font-weight: 600;
  border-collapse: collapse;
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);

  &__header tr th {
    text-align: left;
    padding: 15px 25px;
    border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.5);
  }

  &__body tr {
    border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.5);
    &:last-child {
      border-bottom: none;
    }
    td {
      padding: 15px 25px;
    }
    .state {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &.light {
    background-color: $white-foreground-color;

    .table__body tr td:nth-child(2n) {
      background-color: #f3f3f3;
    }
  }

  &.dark {
    background-color: #1A1F2D;

    .table__body tr td:nth-child(2n) {
      background-color: #131722;
    }
  }
}

.credentials {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  padding: 24px;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    gap: 15px;
  }

  input {
    background: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: $white-text-color;
    width: 100%;
  }
}

.credentialsPassword {
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    cursor: pointer;
  }

  &.light {
    input {
      color: $black-text-color;
    }
  }
}

.credentialsLine {
  outline: none;
  border: 1px solid #5c5c5e;
}

.copyButton {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media screen and (max-width: 1439px) {
    .card {
      flex-grow: 1;
    }

    .lastCard {
      width: 100%;
    }
  }
}

.accountCardsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
}

.sectionTitle {
  display: flex;
  align-items: center;
  margin: 50px 0px 20px 0px;
  gap: 5px;
}

.infoCardsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 50px;

  padding: 0px 10%;

  @media screen and (max-width: 1440px) {
    padding: 0;
  }

  @media screen and (max-width: 1024px) {
    gap: 20px;
    flex-direction: column;
  }
}

.infoCard {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px 30px 40px 30px;

  .iconContainer {
    margin-bottom: 80px;

    .icon {
      position: absolute;
      top: 20px;
      left: 0px;
      right: 0px;
    }
  }

  .content {
    flex-grow: 1;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  h2,
  h3,
  li {
    font-weight: 600;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  h3,
  .subtitle {
    font-size: 16px;
    color: $green-color;
  }

  .subtitle {
    color: $primary-color;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 30px 0px;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 20%;
      text-align: left;
      gap: 10px;

      svg {
        fill: $green-color !important;
      }
    }
  }

  p {
    margin: 30px 0px;
  }

  button {
    width: 100%;
    font-weight: 700;
    font-size: 16px;
  }
}

.stepper {
  .stepperComponents {
    margin-top: 20px;
    width: 100%;
  }
}
