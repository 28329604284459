@import '../../scss/utils/variables';

.logo {
  height: 80px;
  width: auto;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  gap: 50px;

  button {
    width: 100%;
    font-weight: 600;
  }
}

.titles {
  font-weight: 700;

  h1 {
    font-size: 32px;
  }
}

.formContainer {
  width: 400px;

  @media screen and (max-width: 767px) {
    width: 95%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  text-align: left;
}
