.checkboxContainer {
  display: flex;
  flex-direction: column;
}

@media (max-width: 2040px) {
  .checkboxContainer {
    flex-direction: row;
  }
}

@media (max-width: 960px) {
  .checkboxContainer {
    flex-direction: column;
  }
}

@media (max-width: 829px) {
  .checkboxContainer {
    flex-direction: row;
  }
}
