.card {
  border: 2px solid transparent;
  cursor: pointer;

  // &:hover {
  //   border: 2px solid rgb(209, 72, 72);
  // }
}

.hidden {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.01);
  pointer-events: auto;
}

.actions {
  display: flex;
  justify-content: space-between;
}
