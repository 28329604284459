.banner {
  position: relative;
  height: 30vh;
  min-height: 300px;
  border-radius: 24px;
  background: radial-gradient(
    circle,
    rgba(211, 84, 84, 1) 0%,
    rgba(207, 53, 53, 1) 100%
  ) !important;
}

.logoRmf {
  position: absolute;
  height: clamp(150px, 10vw, 10vw);
  left: -65px;
  width: auto;
  z-index: 10;
  animation: animation 15s ease-in-out infinite;
}

.logoDiscord {
  position: absolute;
  bottom: -50px;
  right: 0px;
  height: clamp(300px, 19vw, 19vw);
  width: auto;
  animation: animation 15s ease-in-out infinite;
  animation-delay: 1s;
}

.cards {
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes animation {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5px, -5px);
  }
  20% {
    transform: translate(5px, -5px);
  }
  30% {
    transform: translate(-5px, 5px);
  }
  40% {
    transform: translate(5px, 5px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  60% {
    transform: translate(5px, 5px);
  }
  70% {
    transform: translate(-5px, 5px);
  }
  80% {
    transform: translate(5px, -5px);
  }
  90% {
    transform: translate(-5px, -5px);
  }
}
