@import '../../../scss/utils/variables';

.tableContainer {
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 30px;
  &::-webkit-scrollbar {
    display: none;
  }

  &:hover::-webkit-scrollbar {
    display: block;
  }

  .modalButtons {
    display: flex;
    gap: 20px;

    button {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.challengesTable {
  width: 100%;
  font-weight: 600;
  border-collapse: collapse;
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);

  .tableHead tr th {
    white-space: nowrap;
    text-align: center;
    padding: 15px 20px;
    border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.5);
  }

  &__body tr {
    text-align: center;
    border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.5);
    &:last-child {
      border-bottom: none;
    }
    td {
      white-space: nowrap;
      padding: 20px;
    }

    .actions {
      display: flex;
      justify-content: center;
      gap: 8px;
      svg:hover {
        cursor: pointer;
      }
    }
  }

  &.light {
    background-color: $white-foreground-color;

    .challengesTable__body tr:nth-child(2n - 1) {
      background-color: #f3f3f3;
    }
  }

  &.dark {
    background-color: #1A1F2D;

    .challengesTable__body tr:nth-child(2n - 1) {
      background-color: #131722;
    }
  }
}
