@import '../../../scss/utils/variables';
@import '../../../scss/utils/mixins';

.card {
  display: flex;
  padding: 24px 40px;
  border-radius: 35px;
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  flex-basis: 0;

  @include themes;

  .content {
    width: 70%;
    height: 100%;
    h3,
    p {
      font-weight: 600;
    }

    h3 {
      font-size: 16px;
      margin-bottom: 20px;
    }

    p {
      font-size: 34px;
    }

    .percent {
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 2px;

      &.red {
        color: $primary-color;
      }
      &.green {
        color: $green-color;
      }
    }

    .action {
      margin-bottom: 6px;
      font-size: 16px;
      color: $light-blue-color;
      cursor: pointer;
    }

    button {
      margin-top: 5px;
      padding: 5px 16px;
    }
  }

  .icon {
    display: flex;
    justify-content: flex-end;
    width: 30%;

    svg {
      fill: #cccccc !important;

      @media screen and (max-width: 424px) {
        width: 33px;
        height: 33px;
      }
    }
  }
}
