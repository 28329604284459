@import '../../../scss/utils/variables';

@mixin button($background, $color, $border, $hoverColor) {
  color: $color;
  background-color: $background;
  border: $border;
  text-align: center;
  padding: 10px 25px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background-color: $hoverColor;
  }
}

.primary {
  @include button(
    $primary-color,
    $white-text-color,
    none,
    lighten($color: $primary-color, $amount: 6)
  );
}

.secondary {
  @include button(
    #3988ff,
    $white-text-color,
    none,
    lighten($color: #3988ff, $amount: 6)
  );
}

.outline {
  @include button(
    rgba($white-foreground-color, 0),
    $black-text-color,
    2px solid $gray-color,
    rgba($gray-color, 0.5)
  );

  &.light {
    color: $black-text-color;
  }

  &.dark {
    color: $white-text-color;
  }
  padding: 8px 23px;
}

.red-outline {
  @include button(
    rgba($white-foreground-color, 0),
    $black-text-color,
    2px solid $primary-color,
    rgba($gray-color, 0.5)
  );

  &.light {
    color: $primary-color;
  }

  &.dark {
    color: $primary-color;
  }
  padding: 8px 23px;
}

.green-outline {
  @include button(
    rgba($white-foreground-color, 0),
    $black-text-color,
    2px solid $green-color,
    rgba($gray-color, 0.5)
  );

  &.light {
    color: $green-color;
  }

  &.dark {
    color: $green-color;
  }
  padding: 8px 23px;
}

.green-primary {
  @include button(
    $green-color,
    $white-text-color,
    none,
    lighten($green-color, 10)
  );
  padding: 8px 23px;
}

.rounded-outline {
  @include button(
    rgba($white-foreground-color, 0),
    $primary-color,
    2px solid $primary-color,
    $primary-color
  );

  border-radius: 25px;

  &:hover {
    color: $white-text-color;
  }
}

.border-bottom {
  @include button(#3b3b3b, $white-text-color, none, $primary-color);
  border-bottom: 3px solid $primary-color;
  border-radius: 0px;
}

.disabled {
  @include button(
    #4c4c4c,
    $white-text-color,
    none,
    lighten($color: #4c4c4c, $amount: 6)
  );
}
