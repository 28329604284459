@import '../../scss/utils/variables';

.stepperContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.step {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 50px;
}

.circle {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  overflow: hidden;

  .loaderInsideCircle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: none;
  }

  .stepNumber {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 1;
  }
}

.activeCircle {
  background-color: $primary-color;
}

.stepTitle {
  margin-top: 5px;
}

.stepContent {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.navigationButtons {
  margin-top: 50px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 868px) {
  // Utilisez le même breakpoint que précédemment
  .stepperContainer {
    flex-direction: column;
    align-items: flex-start; // Alignez les éléments sur le début du conteneur flex (gauche)
  }

  .step {
    // Ajustez la disposition pour une orientation verticale
    flex-direction: row;
    align-items: center; // Alignez les cercles et les titres au centre verticalement
    justify-content: flex-start; // Alignez horizontalement au début
  }

  .stepTitle {
    margin-left: 10px;
  }
}
