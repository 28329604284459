@import '../../scss/utils/variables';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 15px;

  span {
    font-size: 18px;
  }
}

.button {
  background: transparent;
  border: none;
  cursor: pointer;
}
