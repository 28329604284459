.parent {
  width: 100%;
  display: flex;
  margin-top: 50px;
}

.panel {
  position: relative;
  width: 25%;
}

.panelInner {
  padding-top: 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding-right: 16px;
  overflow-y: hidden;

  &:hover {
    overflow-y: scroll;
    padding-right: 8px;
  }
}

.content {
  width: 75%;
}

/* ------------------------------------------------------------------ */
/* Ajout de l'animation de rebond et des styles pour la zone "no account" */
/* ------------------------------------------------------------------ */

@keyframes bounceIcon {
  0%, 20%, 53%, 80%, 100% {
    transform: translateY(0);
  }
  40%, 43% {
    transform: translateY(-25px);
  }
  70% {
    transform: translateY(-12px);
  }
  90% {
    transform: translateY(-4px);
  }
}

/** Conteneur principal pour le "no account" */
.noAccountContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 12rem); /* Ajustez si nécessaire */
  gap: 10px;
}

/** Icône portefeuille (ou autre) qui rebondit */
.walletIconBounce {
  width: 80px;
  height: 80px;
  background: linear-gradient(to bottom right, #3b82f6, #2563eb);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  animation: bounceIcon 2s infinite;
  box-shadow: 0 8px 24px rgba(59, 130, 246, 0.2);
}

.noAccountTitle {
  font-size: 2rem; /* équivalent ~ "text-4xl" */
  font-weight: bold;
  background: linear-gradient(to right, #ffffff, rgba(255,255,255,0.6));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Pour l’effet gradient sur le texte */
  margin-bottom: 16px;
}

.noAccountSubtitle {
  color: rgba(255,255,255,0.7);
  margin-bottom: 20px;
  font-size: 1.125rem; /* ~ "text-lg" */
}

.noAccountButton {
  background: linear-gradient(to right, #2563eb, #3b82f6);
  color: #fff;
  border: none;
  border-radius: 0.75rem;
  padding: 16px 32px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(59, 130, 246, 0.25);
}

.noAccountButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(59, 130, 246, 0.25);
}

/* ------------------------------------------------------------------ */
