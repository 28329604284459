@import '../../../scss/utils/variables';
@import '../../../scss/utils/mixins';

.container {
  position: relative;
  padding-left: 28px;
  cursor: pointer;

  & input {
    display: none;

    &:checked ~ .checkmark {
      background-color: $primary-color;
      border: 1px solid $primary-color;

      &::after {
        display: block;
      }
    }
  }
}

.checkmark {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  @include themes;

  &.dark {
    border: 1px solid $white-foreground-color;
  }

  &.light {
    border: 1px solid $black-foreground-color;
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
