@import '../../../scss/utils/variables';

.select {
  width: 100%;
  padding: 10px;
  margin-top: 6px;
  background-color: transparent;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  cursor: pointer;

  &.dark {
    border: 2px solid #4f4f51;
    color: $white-text-color;
    option {
      background-color: $black-foreground-color;
      color: $white-text-color;
    }
  }

  &.light {
    border: 2px solid #d4d4d4;
    color: $black-text-color;
    option {
      background-color: $white-foreground-color;
      color: $black-text-color;
    }
  }

  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z' fill='rgba(153,153,153,1)'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 30px;
}
