@import '../../scss/utils/variables';

.wrapper {
  position: relative;
}

.dropdown {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-height: 70px;
  padding: 17px 30px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);

  hr {
    margin: 20px 0px;
    border: 1px solid rgba($color: #48494b, $alpha: 0.9);
  }

  &.light {
    background-color: $white-background-color;
  }

  &.dark {
    background-color: #1A1F2D;
  }
}

.header,
.content {
  display: flex;
  justify-content: space-between;
}

.header {
  display: flex;
  align-items: center;

  @media screen and (max-width: 425px) {
    // display: none;
  }

  h3 {
    font-weight: 600;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 50px;
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.active {
  max-height: 9999px;
}

.icons {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.headerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
}
