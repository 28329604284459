@import '../../scss/utils/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 50px;

  section {
    h2 {
      margin-bottom: 10px;
      font-weight: 600;
    }
  }

  form {
    display: flex;
    display: flex;
    flex-direction: column;
    gap: 50px;
    button {
      width: 200px;
      align-self: center;
      font-weight: 700;
      font-size: 16px;
    }

    input:disabled {
      border: 2px solid $primary-color;
    }
  }

  button {
    width: 200px;
    align-self: center;
    font-weight: 700;
    font-size: 16px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  border-radius: 8px;

  flex-grow: 1;
  overflow-x: scroll;

  @media screen and (max-width: 1023px) {
    gap: 60px;
  }

  .upload {
    .filesList {
      display: flex;
      gap: 10px;
    }

    button {
      padding: 0px;
      width: fit-content;
    }

    label {
      display: flex;
      gap: 10px;
      padding: 8px 20px;
      color: #909090;
      cursor: pointer;
      white-space: nowrap;
      svg {
        fill: #909090 !important;
      }
    }
  }

  .formGroup {
    display: flex;
    gap: 60px;

    @media screen and (max-width: 1023px) {
      flex-wrap: wrap;
      gap: 20px;
    }

    .form {
      width: 50%;

      @media screen and (max-width: 1023px) {
        width: 100%;
      }
    }
  }
}

.table {
  margin-bottom: 30px;
  border-radius: 6px;
  font-weight: 600;
  border-collapse: collapse;
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);

  &__header tr th {
    text-align: left;
    padding: 15px 25px;
    border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.2);
  }

  &__body tr td {
    padding: 15px 25px;
  }

  &__body tr:nth-child(1) {
    border-bottom: 2px solid rgba($color: #48494b, $alpha: 0.2);
  }

  &.light {
    background-color: $white-background-color;

    .table__body tr td:nth-child(2) {
      background-color: darken($color: $white-background-color, $amount: 4);
    }
  }

  &.dark {
    background-color: #1A1F2D;

    .table__body tr td:nth-child(2) {
      background-color: #131722;
    }
  }
}

.kycUploadInput {
  display: none;
  cursor: pointer;
}

.kycUploadLabel {
  cursor: pointer;

  .uploadText,
  .deleteText {
    display: flex;
    gap: 5px;
    align-items: center;

    span {
      width: 190px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .sharedText {
    color: #00C2FF;
  }

  .uploadText {
    color: "#212120";
  }
  .deleteText {
    color: $primary-color;
  }
}
