@import '../../../scss/utils/variables';

.tooltipContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip {
  display: none;
  position: absolute;
  background-color: rgba($color: #141414, $alpha: 0.8);
  border-radius: 5px;
  padding: 8px;
  color: white;
  z-index: 9999;
  text-align: center;
  width: 350px;
}

.tooltipContainer:hover .tooltip {
  display: block;
}

.top {
  bottom: calc(100% - -10px);
  left: 50%;
  transform: translateX(-50%);
}

.right {
  top: 50%;
  left: calc(100% - -10px);
  transform: translateY(-50%);
}

.bottom {
  top: calc(100% - -10px);
  left: 50%;
  transform: translateX(-50%);
}

.left {
  top: 50%;
  right: calc(100% - -10px);
  transform: translateY(-50%);
}
