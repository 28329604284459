@import '../../scss/utils/variables';

.container {
  background-color: #f8d7da;
  border-left: 5px solid $primary-color;
  border-radius: 5px;
  padding: 8px 16px;
  color: $black-text-color;
  margin-bottom: 20px;
}
