@import '../../scss/utils/variables';

.card {
  width: 100%;
  overflow-x: scroll;

  h2 {
    margin-bottom: 20px;
  }

  a {
    color: $light-blue-color;
    font-weight: 600;
  }
}
