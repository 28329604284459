@import '../../../scss/utils/variables';

.container {
  display: flex;
  height: 100vh;
  @media screen and (max-width: 899px) {
    flex-direction: column;
  }

  &.light {
    background-color: $white-background-color;
    color: $black-text-color;
  }

  &.dark {
    background-color: $black-background-color;
    color: $white-text-color;
  }
}

.nav {
  .header {
    display: none;
    @media screen and (max-width: 899px) {
      display: flex;
    }
  }
  .sidebar {
    @media screen and (max-width: 899px) {
      display: none;
    }
  }
}

.main {
  width: 100%;
  padding: clamp(10px, 4vw, 50px) clamp(10px, 2vw, 100px);
  overflow: scroll;
  //? SIDEBAR REWORK
  padding-top: 100px;
}
