@import '../../../scss/utils/variables';
@import '../../../scss/utils/mixins';

.card {
  position: relative;
  padding: 20px;
  border-radius: 20px;
  width: fit-content;
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  @include themes;
}
