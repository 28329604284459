@import '../../../scss/utils/variables';
@import '../../../scss/utils/mixins';

.logo {
  display: flex;
  justify-content: center;

  img {
    height: 80px;
    width: auto;
  }

  .closeLogo {
    display: none;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0px;
  height: 100vh;
  width: 306.78px;
  border-radius: 0px 15px 15px 0px;
  -webkit-box-shadow: 5px 0px 6px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 5px 0px 6px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
  @include themes;

  h2 {
    font-size: 16px;
    font-weight: 700;
  }

  hr {
    height: 2px;
    background-color: rgba($color: $gray-color, $alpha: 0.3);
    border: none;
  }

  @media screen and (max-width: 767px) {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  @media screen and (max-width: 374px) {
    width: 100%;
  }
}

.header {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  button {
    font-size: 18px;
    font-weight: 400;
    width: 100%;
  }
}

.modalButton {
  width: 200px;
  align-self: center;
  font-weight: 700;
  font-size: 16px;
}

.container {
  overflow-x: hidden;
  flex-grow: 1;

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      display: block;
    }
  }
}

.sectionTitle {
  h2 {
    padding: 20px 20px 10px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    display: flex;
    align-items: center;
  }
}

.hideButton {
  position: absolute;
  right: -12.5px;
  width: 25px;
  height: 25px;
  background-color: $primary-color;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.2s;

  svg {
    fill: $white-text-color;
  }

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.settingsContent,
.mainContent {
  ul {
    padding: 20px;
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
}

.mainContent {
  white-space: nowrap;
  // overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 767px) {
    overflow-y: hidden;
    min-height: auto;
    flex-shrink: 0;
  }
}

.settingsContent {
  ul {
    min-height: 100%;

    .settingsItem {
      position: relative;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 8px;
      font-size: 16px;
      border-radius: 5px;
      &:hover {
        background-color: rgba($color: $primary-color, $alpha: 0.2);
        cursor: pointer;
      }
    }
  }

  .languageSelector {
    position: absolute;
    bottom: 50px;
  }
  .flag {
    width: 24px;
    height: 18px;
    border-radius: 2px;
  }
}

.footer {
  margin-bottom: 5px;
  text-align: center;
  font-size: 12px;
  color: $gray-color;
}

.active {
  width: 100px;

  span {
    display: none;
  }

  a,
  .settingsItem {
    justify-content: center;
    width: min-content;
    margin: 0 auto;
    padding: 10px;

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }

  .header {
    padding: 10px;

    .logo {
      img {
        display: none;
      }

      .closeLogo {
        display: block;
      }
    }

    button {
      display: none;
    }
  }

  .sectionTitle {
    h2 {
      padding: 10px;
    }
  }

  .languageSelector {
    position: absolute;
    bottom: 50px;
  }

  .settingsContent,
  .mainContent {
    ul {
      padding: 10px;
    }
  }
}

.tooltip {
  display: none;
  border-radius: 5px;
  position: absolute;
  left: 60px;
  padding: 10px 20px;
  background-color: rgba($color: #000000, $alpha: 0.5);
  white-space: nowrap;
}
